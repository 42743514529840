export default [
    {
        path: 'wallet',
        name: 'mdeWallet',
        component: () => import('../../views/module/wallet/App.vue'),
        children: [
            {
                path: 'busExtract',
                name: 'mdeWalletBusExtract',
                component: () => import('../../views/module/wallet/extract/Business.vue')
            },
            {
                path: 'busExtractCreate',
                name: 'mdeWalletBusExtractCreate',
                component: () => import('../../views/module/wallet/extract/BusinessCreate.vue')
            },
            {
                path: 'busExtractExchange',
                name: 'mdeWalletBusExtractExchange',
                component: () => import('../../views/module/wallet/extract/BusinessExchange.vue')
            },
            {
                path: 'busRecord',
                name: 'mdeWalletBusRecord',
                component: () => import('../../views/module/wallet/record/Business.vue')
            },
            {
                path: 'cusExtract',
                name: 'mdeWalletCusxtract',
                component: () => import('../../views/module/wallet/extract/Customer.vue')
            },
            {
                path: 'cusExtractCreate',
                name: 'mdeWalletCusExtractCreate',
                component: () => import('../../views/module/wallet/extract/CustomerCreate.vue')
            },

            {
                path: 'cusRecord',
                name: 'mdeWalletCusRecord',
                component: () => import('../../views/module/wallet/record/Customer.vue')
            }
        ]
    }
]
