const global = {
    APP_CUSTOMER_IP: 'app_customer_ip',
    APP_CUSTOMER_IMSI: 'app_customer_imsi',
    APP_CUSTOMER_LOCATION: 'app_customer_location',
    MEMBER_PERSON_INDEX_BACK: 'member_person_index_back',
    MEMBER_PERSON_MATCH_BACK: 'member_person_match_back',
    MEMBER_PERSON_COMMENT: 'member_person_comment',
    MEMBER_PERSON_MATCH_CHOSE: 'member_person_match_chose',
    GATHER_DETAIL_INDEX_TYPE: 'gather_detail_index_type',
    PEOPLE_PROFILE_ATTR: 'people_profile_attr',
    PEOPLE_PERSON_VIEW_BACK: 'people_person_view_back',
    PEOPLE_PERSON_MATCH_BACK: 'people_person_match_back',
    PEOPLE_PERSON_DTLEDIT_BACK: 'people_person_dtledit_back',
    RECRUIT_HOME_INDEX_BACK: 'recruit_home_index_back',
    RECRUIT_CONSULT_INDEX_BACK: 'recruit_consult_index_back',
    RECRUIT_SEARCH_SIFT: 'recruit_search_sift',
    RECRUIT_ACTIVITY_WORKER_BACK: 'recruit_activity_worker_back',
    RECRUIT_ACTIVITY_FARMER_BACK: 'recruit_activity_farmer_back',
    SELLER_PERSON_INDEX_BACK: 'seller_person_index_back',
    TRAIN_DETAIL_INDEX_SUBMIT: 'train_detail_index_submit',
    TRAIN_CERT_CERTTASK_BACK: 'train_cert_certtask_back',
    PROMOTE_GATHER_DETAIL_BACK: 'promote_gather_detail_back',
    CONTENT_HOME_INDEX_BACK: 'content_home_index_back',
    CONTENT_ARTICLE_INDEX_BACK: 'content_article_index_back',
    INFORM_HOME_INDEX_BACK: 'inform_home_index_back',
    INFORM_DETAIL_INDEX_BACK: 'inform_detail_index_back',
    ACADEMIC_HOME_INDEX_BACK: 'academic_home_index_back',
    ACADEMIC_DETAIL_INDEX_BACK: 'academic_detail_index_back',
    CERT_HOME_INDEX_BACK: 'cert_home_index_back',
    CERT_DETAIL_INDEX_BACK: 'cert_detail_index_back',
    CERT_SEARCH_INDEX_BACK: 'cert_search_index_back',
    EDUCATE_HOME_INDEX_BACK: 'educate_home_index_back',
    EDUCATE_DETAIL_INDEX_BACK: 'educate_detail_index_back',
    EDUCATE_SEARCH_INDEX_BACK: 'educate_search_index_back',
    EDUCATE_SEARCH_SIFT: 'educate_search_sift',
    VISA_HOME_INDEX_BACK: 'visa_home_index_back',
    VISA_DETAIL_INDEX_BACK: 'visa_detail_index_back',
    VISA_SEARCH_INDEX_BACK: 'visa_search_index_back',
    STUDY_HOME_INDEX_BACK: 'study_home_index_back',
    STUDY_DETAIL_INDEX_BACK: 'study_detail_index_back',
    STUDY_SEARCH_INDEX_BACK: 'study_search_index_back',
    LABOR_HOME_INDEX_BACK: 'labor_home_index_back',
    LABOR_DETAIL_INDEX_BACK: 'labor_detail_index_back',
    LABOR_SEARCH_INDEX_BACK: 'labor_search_index_back',
    DEGREE_HOME_INDEX_BACK: 'degree_home_index_back',
    DEGREE_DETAIL_INDEX_BACK: 'degree_detail_index_back',
    DEGREE_SEARCH_INDEX_BACK: 'degree_search_index_back',
    HIRE_HOME_INDEX_BACK: 'hire_home_index_back',
    HIRE_DETAIL_INDEX_BACK: 'hire_detail_index_back'
}
function bindToWindow (obj) {
    window.const = {}
    window.const.global = {}
    for (var key in obj) {
        window.const.global[key] = obj[key]
    }
}
bindToWindow(global)
