
export default [
    {
        path: 'inform',
        name: 'mdeInform',
        component: () => import('../../views/module/inform/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeInformHome',
                component: () => import('../../views/module/inform/home/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeInformDetail',
                component: () => import('../../views/module/inform/detail/Index.vue')
            },
            {
                path: 'signup',
                name: 'mdeInformSignup',
                component: () => import('../../views/module/inform/signup/Index.vue')
            },
            {
                path: 'totur',
                name: 'mdeInformTotur',
                component: () => import('../../views/module/inform/totur/Index.vue')
            },
            {
                path: 'order',
                name: 'mdeInformOrder',
                component: () => import('../../views/module/inform/order/Index.vue')
            }
        ]
    }
]
