
export default [
    {
        path: 'home',
        name: 'workHome',
        component: () => import('../views/work/home/Index.vue')
    },
    {
        path: 'helper',
        name: 'workHelper',
        component: () => import('../views/work/helper/App.vue'),
        children: [
            {
                path: 'home',
                name: 'workHelperHome',
                component: () => import('../views/work/helper/home/Index.vue')
            },
            {
                path: 'item',
                name: 'workHelperItem',
                component: () => import('../views/work/helper/item/Index.vue')
            },
            {
                path: 'operate',
                name: 'workHelperOperate',
                component: () => import('../views/work/helper/operate/Index.vue')
            }
        ]
    },
    {
        path: 'person',
        name: 'workPerson',
        redirect: '/mde/member/person'
    },
    {
        path: 'contact',
        name: 'workContact',
        component: () => import('../views/work/contact/Index.vue')
    },
    {
        path: 'paid',
        name: 'workPaid',
        component: () => import('../views/work/paid/Index.vue')
    },
    {
        path: 'own',
        name: 'workOwn',
        component: () => import('../views/work/own/Index.vue')
    }
]
