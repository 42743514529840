
export default [
    {
        path: 'gift',
        name: 'mdeGift',
        component: () => import('../../views/module/gift/App.vue'),
        children: [
            {
                path: 'detail',
                name: 'mdeGiftDetail',
                component: () => import('../../views/module/gift/detail/Index.vue')
            }
        ]
    }
]
