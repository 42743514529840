import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import qs from 'qs'
import { Button, Row, Col, Tabbar, TabbarItem, NavBar, Loading, Popup, Dialog } from 'vant'
import 'vant/lib/index.css'
import './assets/css/global.css'
import './assets/css/icon.css'
import './common/const/global.js'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.interceptors.request.use(config => {
    config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('SESSION_TOKEN')
    return config
})

const app = createApp(App)
app.config.globalProperties.SESSION_PLATFORM = 'SESSION_PLATFORM'
app.config.globalProperties.SESSION_APP = 'SESSION_APP'
app.config.globalProperties.SESSION_SUBSCRIBE = 'SESSION_SUBSCRIBE'
app.config.globalProperties.SESSION_TOKEN = 'SESSION_TOKEN'
app.config.globalProperties.SESSION_USER = 'SESSION_USER'
app.config.globalProperties.SESSION_MERCHANT = 'SESSION_MERCHANT'
app.config.globalProperties.SESSION_ROLE = 'SESSION_ROLE'
app.config.globalProperties.SESSION_REFEREE = 'SESSION_REFEREE'
app.config.globalProperties.SESSION_REFEREE_TYPE = 'SESSION_REFEREE_TYPE'
app.config.globalProperties.SESSION_OPERATOR = 'SESSION_OPERATOR'
app.config.globalProperties.SESSION_SOURCE_LABEL = 'SESSION_SOURCE_LABEL'
app.config.globalProperties.SESSION_SOURCE_TYPE = 'SESSION_SOURCE_TYPE'
app.config.globalProperties.SESSION_SELLER = 'SESSION_SELLER'
app.config.globalProperties.SESSION_SELLER_TYPE = 'SESSION_SELLER_TYPE'
app.config.globalProperties.SESSION_SELLER_TYPE = 'SESSION_SELLER_MODEL'
app.config.globalProperties.SESSION_SERIAL = 'SESSION_SERIAL'
app.config.globalProperties.SESSION_SITE = 'SESSION_SITE'
app.config.globalProperties.SESSION_SUPPLIER = 'SESSION_SUPPLIER'
app.config.globalProperties.SESSION_BACKURL = 'SESSION_BACKURL'
app.config.globalProperties.COLOR = '#00CC99'
app.config.globalProperties.COLOR_M = '#00CC99'
app.config.globalProperties.COLOR_S1 = '#ff9966'
app.config.globalProperties.COLOR_S2 = '#ff554d'
app.config.globalProperties.UMS_URL = 'https://server.utp.ink:10101'
app.config.globalProperties.BMS_URL = 'https://server.utp.ink:10301'
app.config.globalProperties.STATIC_URL = 'https://server.utp.ink/static'
app.config.globalProperties.IMAGE_DOMAIN = 'https://open-image-1312178943.cos.ap-chengdu.myqcloud.com'
app.config.globalProperties.$http = axios
app.config.globalProperties.$qs = qs
app.config.globalProperties.$dialog = Dialog
app.use(router)
app.use(Button).use(Row).use(Col).use(Tabbar).use(TabbarItem).use(NavBar).use(Loading).use(Popup)
app.mount('#app')
