
export default [
    {
        path: 'content',
        name: 'mdeContent',
        component: () => import('../../views/module/content/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeContentHome',
                component: () => import('../../views/module/content/home/Index.vue')
            },
            {
                path: 'article',
                name: 'mdeContentArticle',
                component: () => import('../../views/module/content/article/Index.vue')
            }
        ]
    }
]
