import { createRouter, createWebHistory } from 'vue-router'
import mdeAccount from './module/account.js'
import mdeEnroll from './module/enroll.js'
// import mdeJoin from './module/join.js'
import mdeRecruit from './module/recruit.js'
import mdeShare from './module/share.js'
import mdePeople from './module/people.js'
// import mdeMedia from './module/media.js'
import mdeTrain from './module/train.js'
// import mdeSearch from './module/search.js'
import mdeWallet from './module/wallet.js'
import mdeMember from './module/member.js'
// import mdeApprove from './module/approve.js'
// import mdeLove from './module/love.js'
// import mdeSupply from './module/supply.js'
import mdeHelp from './module/help.js'
// import mdeStorage from './module/storage.js'
// import mdePromote from './module/promote.js'
import mdeContent from './module/content.js'
import mdeInform from './module/inform.js'
import mdeInstitution from './module/institution.js'
import mdeGift from './module/gift.js'
import mdeOverall from './module/overall.js'
import mdeAcademic from './module/academic.js'
import mdeEducate from './module/educate.js'
import mdeCert from './module/cert.js'
import mdeVisa from './module/visa.js'
import mdeStudy from './module/study.js'
import mdeLabor from './module/labor.js'
import mdeDegree from './module/degree.js'
import mdeHire from './module/hire.js'
import work from './work.js'
import seller from './seller.js'
import integrate from './integrate.js'
const routes = [
  {
    path: '/mde',
    name: 'module',
    component: () => import('../views/module/App.vue'),
    children: [
      {
        path: 'bind',
        name: 'mdeBind',
        component: () => import('../views/module/bind/Bind.vue')
      },
      {
        path: 'focus',
        name: 'mdeFocus',
        component: () => import('../views/module/focus/Index.vue')
      },
      {
        path: 'login',
        name: 'mdeLogin',
        component: () => import('../views/module/login/Login.vue')
      },
      ...mdeShare,
      ...mdeEnroll,
      // ...mdeJoin,
      ...mdeRecruit,
      ...mdePeople,
      // ...mdeMedia,
      ...mdeTrain,
      // ...mdeSearch,
      ...mdeWallet,
      ...mdeMember,
      // ...mdeApprove,
      // ...mdeLove,
      // ...mdeSupply,
      ...mdeHelp,
      // ...mdeStorage,
      // ...mdePromote,
      ...mdeAccount,
      ...mdeContent,
      ...mdeInform,
      ...mdeInstitution,
      ...mdeGift,
      ...mdeOverall,
      ...mdeAcademic,
      ...mdeCert,
      ...mdeEducate,
      ...mdeVisa,
      ...mdeStudy,
      ...mdeLabor,
      ...mdeDegree,
      ...mdeHire
    ]
  },
  {
    path: '/work',
    name: 'work',
    redirect: '/work/home',
    component: () => import('../views/work/App.vue'),
    children: [
      ...work
    ]
  },
  {
    path: '/seller',
    name: 'seller',
    component: () => import('../views/seller/App.vue'),
    children: [...seller]
  },
  {
    path: '/integrate',
    name: 'integrate',
    component: () => import('../views/integrate/App.vue'),
    children: [...integrate]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
