export default [
    {
        path: 'member',
        name: 'mdeMember',
        component: () => import('../../views/module/member/App.vue'),
        children: [
            {
                path: 'person',
                name: 'mdeMemberPerson',
                component: () => import('../../views/module/member/person/Index.vue')
            },
            {
                path: 'personMatch',
                name: 'mdeMemberPersonMatch',
                component: () => import('../../views/module/member/person/Match.vue')
            },
            {
                path: 'personMatchDetail',
                name: 'mdeMemberPersonMatchDetail',
                component: () => import('../../views/module/member/person/MatchDetail.vue')
            },
            {
                path: 'personCreate',
                name: 'mdeMemberPersonCreate',
                component: () => import('../../views/module/member/person/Create.vue')
            }
            // {
            //     path: 'resumeInviter',
            //     name: 'personResumeInviter',
            //     component: () => import('../../views/module/people/person/resume/Inviter.vue')
            // },
            // {
            //     path: 'certify',
            //     name: 'personCertify',
            //     component: () => import('../../views/module/people/person/certify/Index.vue')
            // },
            // {
            //     path: 'demand',
            //     name: 'personDemand',
            //     component: () => import('../../views/module/people/person/demand/Index.vue')
            // },
            // {
            //     path: 'demandRct',
            //     name: 'personDemandRct',
            //     component: () => import('../../views/module/people/person/demand/Recruit.vue')
            // },

            // {
            //     path: 'skill',
            //     name: 'personSkill',
            //     component: () => import('../../views/module/people/person/skill/Index.vue')
            // },
            // {
            //     path: 'skillCreate',
            //     name: 'personSkillCreate',
            //     component: () => import('../../views/module/people/person/skill/Create.vue')
            // },
            // {
            //     path: 'skillUpdate',
            //     name: 'personSkillUpdate',
            //     component: () => import('../../views/module/people/person/skill/Update.vue')
            // },
            // {
            //     path: 'cert',
            //     name: 'personCert',
            //     component: () => import('../../views/module/people/person/cert/Index.vue')
            // },
            // {
            //     path: 'certCreate',
            //     name: 'personCertCreate',
            //     component: () => import('../../views/module/people/person/cert/Create.vue')
            // },
            // {
            //     path: 'certUpdate',
            //     name: 'personCertUpdate',
            //     component: () => import('../../views/module/people/person/cert/Update.vue')
            // },
            // {
            //     path: 'honor',
            //     name: 'personHonor',
            //     component: () => import('../../views/module/people/person/honor/Index.vue')
            // },
            // {
            //     path: 'honorCreate',
            //     name: 'personHonorCreate',
            //     component: () => import('../../views/module/people/person/honor/Create.vue')
            // },
            // {
            //     path: 'honorUpdate',
            //     name: 'personHonorUpdate',
            //     component: () => import('../../views/module/people/person/honor/Update.vue')
            // },
            // {
            //     path: 'edu',
            //     name: 'personEdu',
            //     component: () => import('../../views/module/people/person/edu/Index.vue')
            // },
            // {
            //     path: 'eduCreate',
            //     name: 'personEduCreate',
            //     component: () => import('../../views/module/people/person/edu/Create.vue')
            // },
            // {
            //     path: 'eduUpdate',
            //     name: 'personEduUpdate',
            //     component: () => import('../../views/module/person/edu/Update.vue')
            // },
            // {
            //     path: 'work',
            //     name: 'personWork',
            //     component: () => import('../../views/module/person/work/Index.vue')
            // },
            // {
            //     path: 'workCreate',
            //     name: 'personWorkCreate',
            //     component: () => import('../../views/module/person/work/Create.vue')
            // },
            // {
            //     path: 'workUpdate',
            //     name: 'personWorkUpdate',
            //     component: () => import('../../views/module/person/work/Update.vue')
            // },
            // {
            //     path: 'family',
            //     name: 'personFamily',
            //     component: () => import('../../views/module/person/family/Index.vue')
            // },
            // {
            //     path: 'familyCreate',
            //     name: 'personFamilyCreate',
            //     component: () => import('../../views/module/person/family/Create.vue')
            // },
            // {
            //     path: 'familyUpdate',
            //     name: 'personFamilyUpdate',
            //     component: () => import('../../views/module/person/family/Update.vue')
            // },
            // {
            //     path: 'project',
            //     name: 'personProject',
            //     component: () => import('../../views/module/person/project/Index.vue')
            // },
            // {
            //     path: 'projectCreate',
            //     name: 'personProjectCreate',
            //     component: () => import('../../views/module/person/project/Create.vue')
            // },
            // {
            //     path: 'projectUpdate',
            //     name: 'personProjectUpdate',
            //     component: () => import('../../views/module/person/project/Update.vue')
            // }
        ]
    }
]
