export default [
    {
        path: 'home',
        name: 'sellerHome',
        component: () => import('../views/seller/home/Index.vue'),
        children: [{
            path: 'recruit',
            name: 'sellerHomeRecruit',
            component: () => import('../views/seller/home/Recruit.vue')
        }, {
            path: 'train',
            name: 'sellerHomeTrain',
            component: () => import('../views/seller/home/Train.vue')
        }, {
            path: 'enroll',
            name: 'sellerHomeEnroll',
            component: () => import('../views/seller/home/Enroll.vue')
        }, {
            path: 'love',
            name: 'sellerHomeLove',
            component: () => import('../views/seller/home/Love.vue')
        }]
    },
    {
        path: 'login',
        name: 'sellerLogin',
        component: () => import('../views/seller/login/Login.vue')
    },
    {
        path: 'register',
        name: 'sellerRegister',
        component: () => import('../views/seller/register/Register.vue')
    },
    {
        path: 'inviter',
        name: 'inviterHome',
        component: () => import('../views/seller/inviter/Index.vue')
    },
    {
        path: 'apply',
        name: 'sellerApply',
        component: () => import('../views/seller/apply/Index.vue')
    },
    {
        path: 'site',
        name: 'sellerSite',
        component: () => import('../views/seller/site/Index.vue')
    }, {
        path: 'siteHire',
        name: 'sellerSiteHire',
        component: () => import('../views/seller/site/HireSite.vue')
    }, {
        path: 'order',
        name: 'sellerOrder',
        component: () => import('../views/seller/order/Index.vue')
    },
    {
        path: 'task',
        name: 'sellerTask',
        component: () => import('../views/seller/task/Index.vue')
    },
    {
        path: 'search',
        name: 'sellerSearch',
        component: () => import('../views/seller/search/Index.vue')
    },
    {
        path: 'own',
        name: 'sellerOwn',
        component: () => import('../views/seller/own/Index.vue')
    },
    {
        path: 'manage',
        name: 'sellerManage',
        component: () => import('../views/seller/manage/Index.vue')
    },
    {
        path: 'help',
        name: 'sellerHelp',
        component: () => import('../views/seller/help/Index.vue')
    },
    {
        path: 'settle',
        name: 'sellerSettle',
        component: () => import('../views/seller/settle/Index.vue')
    },
    {
        path: 'buy',
        name: 'sellerBuy',
        component: () => import('../views/seller/buy/Index.vue')
    },
    {
        path: 'person',
        name: 'sellerPerson',
        component: () => import('../views/seller/person/Index.vue')
    },
    {
        path: 'personInviter',
        name: 'sellerPersonInviter',
        component: () => import('../views/seller/person/Inviter.vue')
    },
    {
        path: 'consult',
        name: 'sellerConsult',
        component: () => import('../views/seller/consult/Index.vue')
    },
    {
        path: 'consultResult',
        name: 'sellerConsultResult',
        component: () => import('../views/seller/consult/Result.vue')
    },
    {
        path: 'promoteGather',
        name: 'sellerPromoteGather',
        component: () => import('../views/seller/promote/Gather.vue')
    },
    {
        path: 'down',
        name: 'sellerDown',
        component: () => import('../views/seller/down/Index.vue')
    },
    {
        path: 'statsVisit',
        name: 'sellerStatsVisit',
        component: () => import('../views/seller/stats/Visit.vue')
    },
    {
        path: 'statsRegister',
        name: 'sellerStatsRegister',
        component: () => import('../views/seller/stats/Register.vue')
    }
]
