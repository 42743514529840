export default [
    {
        path: 'help',
        name: 'mdeHelp',
        component: () => import('../../views/module/help/App.vue'),
        children: [
            {
                path: 'issue',
                name: 'mdeHelpIssue',
                component: () => import('../../views/module/help/issue/Index.vue')
            }, {
                path: 'issueRead',
                name: 'mdeHelpIssueRead',
                component: () => import('../../views/module/help/issue/Read.vue')
            }
        ]
    }
]
