
export default [
    {
        path: 'recruit',
        name: 'mdeRecruit',
        component: () => import('../../views/module/recruit/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeRecruitHome',
                component: () => import('../../views/module/recruit/home/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeRecruitSearch',
                component: () => import('../../views/module/recruit/search/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeRecruitDetail',
                component: () => import('../../views/module/recruit/detail/Index.vue')
            },
            {
                path: 'consult',
                name: 'mdeRecruitConsult',
                component: () => import('../../views/module/recruit/consult/Index.vue')
            },
            {
                path: 'order',
                name: 'mdeRecruitOrder',
                component: () => import('../../views/module/recruit/order/Index.vue')
            },
            {
                path: 'orderProcess',
                name: 'mdeRecruitOrderProcess',
                component: () => import('../../views/module/recruit/order/Process.vue')
            },
            {
                path: 'orderApplyAudit',
                name: 'mdeRecruitOrderApplyAudit',
                component: () => import('../../views/module/recruit/order/ApplyAudit.vue')
            },
            {
                path: 'orderSupplierAudit',
                name: 'mdeRecruitOrderSupplierAudit',
                component: () => import('../../views/module/recruit/order/SupplierAudit.vue')
            },
            {
                path: 'shareImage',
                name: 'mdeRecruitShareImage',
                component: () => import('../../views/module/recruit/share/Image.vue')
            },
            {
                path: 'own',
                name: 'mdeRecruitOwn',
                component: () => import('../../views/module/recruit/own/Index.vue')
            },
            {
                path: 'activityRctWorker',
                name: 'mdeRecruitActivityRctWorker',
                component: () => import('../../views/module/recruit/activity/RctWorker.vue')
            }
        ]
    }
]
