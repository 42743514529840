
export default [
    {
        path: 'educate',
        name: 'mdeEducate',
        component: () => import('../../views/module/educate/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeEducateHome',
                component: () => import('../../views/module/educate/home/Index.vue')
            }, {
                path: 'search',
                name: 'mdeEducateSearch',
                component: () => import('../../views/module/educate/search/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeEducateDetail',
                component: () => import('../../views/module/educate/detail/Index.vue')
            },
            {
                path: 'signup',
                name: 'mdeEducateSignup',
                component: () => import('../../views/module/educate/signup/Index.vue')
            },
            {
                path: 'order',
                name: 'mdeEducateOrder',
                component: () => import('../../views/module/educate/order/Index.vue')
            }
        ]
    }
]
