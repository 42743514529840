export default [
    {
        path: 'train',
        name: 'mdeTrain',
        component: () => import('../../views/module/train/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeTrainHome',
                component: () => import('../../views/module/train/home/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeTrainDetail',
                component: () => import('../../views/module/train/detail/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeTrainSearch',
                component: () => import('../../views/module/train/search/Index.vue')
            },
            {
                path: 'classify',
                name: 'mdeTrainClassify',
                component: () => import('../../views/module/train/classify/Index.vue')
            },
            {
                path: 'own',
                name: 'mdeTrainOwn',
                component: () => import('../../views/module/train/own/Index.vue')
            },
            {
                path: 'order',
                name: 'mdeTrainOrder',
                component: () => import('../../views/module/train/order/Index.vue')
            },
            {
                path: 'orderProcess',
                name: 'mdeTrainOrderProcess',
                component: () => import('../../views/module/train/order/Process.vue')
            },
            {
                path: 'shareImage',
                name: 'mdeTrainShareImage',
                component: () => import('../../views/module/train/share/Image.vue')
            },
            {
                path: 'course',
                name: 'mdeTrainCourse',
                component: () => import('../../views/module/train/course/Index.vue')
            },
            {
                path: 'courseRoom',
                name: 'mdeTrainCourseRoom',
                component: () => import('../../views/module/train/course/Room.vue')
            },
            {
                path: 'cert',
                name: 'mdeTrainCert',
                component: () => import('../../views/module/train/cert/Index.vue')
            },
            {
                path: 'certTask',
                name: 'mdeTrainCertTask',
                component: () => import('../../views/module/train/cert/CertTask.vue')
            }
        ]
    }
]
