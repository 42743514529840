export default [
    {
        path: 'account',
        name: 'mdeAccount',
        component: () => import('../../views/module/account/App.vue'),
        children: [
            {
                path: 'pwdModify',
                name: 'maeAccountPwdModify',
                component: () => import('../../views/module/account/password/Modify.vue')
            }
        ]
    }
]
