
export default [
    {
        path: 'study',
        name: 'mdeStudy',
        component: () => import('../../views/module/study/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeStudyHome',
                component: () => import('../../views/module/study/home/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeStudySearch',
                component: () => import('../../views/module/study/search/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeStudyDetail',
                component: () => import('../../views/module/study/detail/Index.vue')
            }
        ]
    }
]
