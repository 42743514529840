
export default [
    {
        path: 'degree',
        name: 'mdeDegree',
        component: () => import('../../views/module/degree/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeDegreeHome',
                component: () => import('../../views/module/degree/home/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeDegreeSearch',
                component: () => import('../../views/module/degree/search/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeDegreeDetail',
                component: () => import('../../views/module/degree/detail/Index.vue')
            }
        ]
    }
]
