
export default [
    {
        path: 'hire',
        name: 'mdeHire',
        component: () => import('../../views/module/hire/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeHireHome',
                component: () => import('../../views/module/hire/home/Index.vue')
            },
            {
                path: 'publish',
                name: 'mdeHirePublish',
                component: () => import('../../views/module/hire/publish/Index.vue')
            },
            {
                path: 'publishCreate',
                name: 'mdeHirePublishCreate',
                component: () => import('../../views/module/hire/publish/Create.vue')
            },
            {
                path: 'publishUpdate',
                name: 'mdeHirePublishUpdate',
                component: () => import('../../views/module/hire/publish/Update.vue')
            },
            {
                path: 'seek',
                name: 'mdeHireSeek',
                component: () => import('../../views/module/hire/seek/Index.vue')
            },
            {
                path: 'recharge',
                name: 'mdeHireRecharge',
                component: () => import('../../views/module/hire/recharge/Index.vue')
            },
            {
                path: 'userCertify',
                name: 'mdeHireUserCertify',
                component: () => import('../../views/module/hire/certify/User.vue')
            },
            {
                path: 'supplierCertify',
                name: 'mdeHireSupplierCertify',
                component: () => import('../../views/module/hire/certify/Supplier.vue')
            },
            {
                path: 'detail',
                name: 'mdeHireDetail',
                component: () => import('../../views/module/hire/detail/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeHireSearch',
                component: () => import('../../views/module/hire/search/Index.vue')
            },
            {
                path: 'classify',
                name: 'mdeHireClassify',
                component: () => import('../../views/module/hire/classify/Index.vue')
            },
            {
                path: 'sign',
                name: 'mdeHireSign',
                component: () => import('../../views/module/hire/sign/Index.vue')
            },
            {
                path: 'work',
                name: 'mdeHireWork',
                component: () => import('../../views/module/hire/work/Index.vue')
            },
            {
                path: 'settle',
                name: 'mdeHireSettle',
                component: () => import('../../views/module/hire/settle/Index.vue')
            },
            {
                path: 'location',
                name: 'mdeHireLocation',
                component: () => import('../../views/module/hire/publish/Location.vue')
            }
        ]
    }
]
