export default [
    {
        path: 'overall',
        name: 'mdeOverall',
        component: () => import('../../views/module/overall/App.vue'),
        children: [
            {
                path: 'batch',
                name: 'mdeOverallBatch',
                component: () => import('../../views/module/overall/batch/Index.vue')
            },
            {
                path: 'consult',
                name: 'mdeOverallConsult',
                component: () => import('../../views/module/overall/consult/Index.vue')
            },
            {
                path: 'resever',
                name: 'mdeOverallResever',
                component: () => import('../../views/module/overall/resever/Index.vue')
            }
        ]
    }
]
