
export default [
    {
        path: 'institution',
        name: 'mdeInstitution',
        component: () => import('../../views/module/institution/App.vue'),
        children: [
            {
                path: 'detail',
                name: 'mdeInstitutionDetail',
                component: () => import('../../views/module/institution/detail/Index.vue')
            }
        ]
    }
]
