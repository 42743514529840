
export default [
    {
        path: 'enroll',
        name: 'mdeEnroll',
        component: () => import('../../views/module/enroll/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeEnrollHome',
                component: () => import('../../views/module/enroll/home/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeEnrollDetail',
                component: () => import('../../views/module/enroll/detail/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeEnrollSearch',
                component: () => import('../../views/module/enroll/search/Index.vue')
            },
            {
                path: 'order',
                name: 'mdeEnrollOrder',
                component: () => import('../../views/module/enroll/order/Index.vue')
            },
            {
                path: 'orderProcess',
                name: 'mdeEnrollOrderProcess',
                component: () => import('../../views/module/enroll/order/Process.vue')
            },
            {
                path: 'shareImage',
                name: 'mdeEnrollShareImage',
                component: () => import('../../views/module/enroll/share/Image.vue')
            }
        ]
    }
]
