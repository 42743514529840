
export default [
    {
        path: 'visa',
        name: 'mdeVisa',
        component: () => import('../../views/module/visa/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeVisaHome',
                component: () => import('../../views/module/visa/home/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeVisaSearch',
                component: () => import('../../views/module/visa/search/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeVisaDetail',
                component: () => import('../../views/module/visa/detail/Index.vue')
            }
        ]
    }
]
