
export default [
    {
        path: 'academic',
        name: 'mdeAcademic',
        component: () => import('../../views/module/academic/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeAcademicHome',
                component: () => import('../../views/module/academic/home/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeAcademicDetail',
                component: () => import('../../views/module/academic/detail/Index.vue')
            },
            {
                path: 'signup',
                name: 'mdeAcademicSignup',
                component: () => import('../../views/module/academic/signup/Index.vue')
            },
            {
                path: 'order',
                name: 'mdeAcademicOrder',
                component: () => import('../../views/module/academic/order/Index.vue')
            }
        ]
    }
]
