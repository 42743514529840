
export default [
    {
        path: 'cert',
        name: 'mdeCert',
        component: () => import('../../views/module/cert/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeCertHome',
                component: () => import('../../views/module/cert/home/Index.vue')
            }, {
                path: 'search',
                name: 'mdeCertSearch',
                component: () => import('../../views/module/cert/search/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeCertDetail',
                component: () => import('../../views/module/cert/detail/Index.vue')
            },
            {
                path: 'signup',
                name: 'mdeCertSignup',
                component: () => import('../../views/module/cert/signup/Index.vue')
            },
            {
                path: 'order',
                name: 'mdeCertOrder',
                component: () => import('../../views/module/cert/order/Index.vue')
            }
        ]
    }
]
