export default [
    {
        path: 'people',
        name: 'mdePeople',
        component: () => import('../../views/module/people/App.vue'),
        children: [
            {
                path: 'person',
                name: 'mdePeoplePerson',
                component: () => import('../../views/module/people/person/Index.vue'),
                children: [
                    {
                        path: 'dtlEdit',
                        name: 'mdePeoplePersonDTLEdit',
                        component: () => import('../../views/module/people/person/DTLEdit.vue')
                    },
                    {
                        path: 'dmdEdit',
                        name: 'mdePeoplePersonDMDEdit',
                        component: () => import('../../views/module/people/person/DMDEdit.vue')
                    },
                    {
                        path: 'dtlBase',
                        name: 'mdePeoplePersonDTLBase',
                        component: () => import('../../views/module/people/person/DTLBase.vue')
                    },
                    {
                        path: 'dtlMLife',
                        name: 'mdePeoplePersonDTLMLife',
                        component: () => import('../../views/module/people/person/DTLMLife.vue')
                    },
                    {
                        path: 'dtlSkill',
                        name: 'mdePepPersonDTLSkill',
                        component: () => import('../../views/module/people/person/DTLSkill.vue')
                    },
                    {
                        path: 'dtlSkillEdit',
                        name: 'mdePeoplePersonDTLSkillEdit',
                        component: () => import('../../views/module/people/person/DTLSkillEdit.vue')
                    },
                    {
                        path: 'dtlCertify',
                        name: 'mdePeoplePersonDTLCertify',
                        component: () => import('../../views/module/people/person/DTLCertify.vue')
                    },
                    {
                        path: 'dtlFamily',
                        name: 'mdePeoplePersonDTLFamily',
                        component: () => import('../../views/module/people/person/DTLFamily.vue')
                    },
                    {
                        path: 'dtlFamilyEdit',
                        name: 'mdePeoplePersonDTLFamilyEdit',
                        component: () => import('../../views/module/people/person/DTLFamilyEdit.vue')
                    },
                    {
                        path: 'dtlEntrance',
                        name: 'mdePeoplePersonDTLEntrance',
                        component: () => import('../../views/module/people/person/DTLEntrance.vue')
                    },
                    {
                        path: 'dtlCert',
                        name: 'mdePeoplePersonDTLCert',
                        component: () => import('../../views/module/people/person/DTLCert.vue')
                    },
                    {
                        path: 'dtlCertEdit',
                        name: 'mdePeoplePersonDTLCertEdit',
                        component: () => import('../../views/module/people/person/DTLCertEdit.vue')
                    },
                    {
                        path: 'dtlWork',
                        name: 'mdePeoplePersonDTLWork',
                        component: () => import('../../views/module/people/person/DTLWork.vue')
                    },
                    {
                        path: 'dtlWorkEdit',
                        name: 'mdePeoplePersonDTLWorkEdit',
                        component: () => import('../../views/module/people/person/DTLWorkEdit.vue')
                    },
                    {
                        path: 'dtlEdu',
                        name: 'mdePeoplePersonDTLEdu',
                        component: () => import('../../views/module/people/person/DTLEdu.vue')
                    },
                    {
                        path: 'dtlEduEdit',
                        name: 'mdePeoplePersonDTLEduEdit',
                        component: () => import('../../views/module/people/person/DTLEduEdit.vue')
                    },
                    {
                        path: 'dtlProject',
                        name: 'mdePeoplePersonDTLProject',
                        component: () => import('../../views/module/people/person/DTLProject.vue')
                    },
                    {
                        path: 'dtlProjectEdit',
                        name: 'mdePeoplePersonDTLProjectEdit',
                        component: () => import('../../views/module/people/person/DTLProjectEdit.vue')
                    }, {
                        path: 'dtlHonor',
                        name: 'mdePeoplePersonDTLHonor',
                        component: () => import('../../views/module/people/person/DTLHonor.vue')
                    },
                    {
                        path: 'dtlHonorEdit',
                        name: 'mdePeoplePersonDTLHonorEdit',
                        component: () => import('../../views/module/people/person/DTLHonorEdit.vue')
                    },
                    {
                        path: 'dtlAsset',
                        name: 'mdePeoplePersonDTLAsset',
                        component: () => import('../../views/module/people/person/DTLAsset.vue')
                    },
                    {
                        path: 'dtlHouse',
                        name: 'mdePeoplePersonDTLHouse',
                        component: () => import('../../views/module/people/person/DTLHouse.vue')
                    },
                    {
                        path: 'dtlHouseEdit',
                        name: 'mdePeoplePersonDTLHouseEdit',
                        component: () => import('../../views/module/people/person/DTLHouseEdit.vue')
                    },
                    {
                        path: 'dtlCar',
                        name: 'mdePeoplePersonDTLCar',
                        component: () => import('../../views/module/people/person/DTLCar.vue')
                    },
                    {
                        path: 'dtlCarEdit',
                        name: 'mdePeoplePersonDTLCarEdit',
                        component: () => import('../../views/module/people/person/DTLCarEdit.vue')
                    },
                    {
                        path: 'dtlHobby',
                        name: 'mdePeoplePersonDTLHobby',
                        component: () => import('../../views/module/people/person/DTLHobby.vue')
                    },
                    {
                        path: 'dtlHabit',
                        name: 'mdePeoplePersonDTLHabit',
                        component: () => import('../../views/module/people/person/DTLHabit.vue')
                    },
                    {
                        path: 'dtlPhoto',
                        name: 'mdePeoplePersonDTLPhoto',
                        component: () => import('../../views/module/people/person/DTLPhoto.vue')
                    },
                    {
                        path: 'dtlPhotoEdit',
                        name: 'mdePeoplePersonDTLPhotoEdit',
                        component: () => import('../../views/module/people/person/DTLPhotoEdit.vue')
                    },
                    {
                        path: 'dmdLove',
                        name: 'mdePeoplePersonDMDLove',
                        component: () => import('../../views/module/people/person/DMDLove.vue')
                    },
                    {
                        path: 'view',
                        name: 'mdePeoplePersonView',
                        component: () => import('../../views/module/people/person/View.vue')
                    }
                ]
            }, {
                path: 'match',
                name: 'mdePeopleMatch',
                component: () => import('../../views/module/people/match/Index.vue'),
                children: [
                    {
                        path: 'recruit',
                        name: 'mdePeopleMatchRecruit',
                        component: () => import('../../views/module/people/match/Recruit.vue')
                    }
                ]
            }
        ]
    }
]
