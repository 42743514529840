
export default [
    {
        path: 'labor',
        name: 'mdeLabor',
        component: () => import('../../views/module/labor/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeLaborHome',
                component: () => import('../../views/module/labor/home/Index.vue')
            },
            {
                path: 'search',
                name: 'mdeLaborSearch',
                component: () => import('../../views/module/labor/search/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeLaborDetail',
                component: () => import('../../views/module/labor/detail/Index.vue')
            }
        ]
    }
]
