export default [
    {
        path: 'share',
        name: 'mdeShare',
        redirect: '/share/home',
        component: () => import('../../views/module/join/App.vue'),
        children: [
            {
                path: 'home',
                name: 'mdeShareHome',
                component: () => import('../../views/module/share/home/Index.vue')
            },
            {
                path: 'detail',
                name: 'mdeShareDetail',
                component: () => import('../../views/module/share/detail/Detail.vue')
            }
        ]
    }
]
